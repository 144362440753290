var exports = {};
function _typeof(o) {
  "@babel/helpers - typeof";

  return (exports = _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, exports.__esModule = true, exports["default"] = exports), _typeof(o);
}
exports = _typeof, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;